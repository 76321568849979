






































import {Component, Vue} from 'vue-property-decorator';
import Vuelidate from 'vuelidate';
import {required, sameAs} from 'vuelidate/lib/validators';
import {vxm} from '@/store';

Vue.use(Vuelidate);

@Component({
  components: {},
  validations: {
    // adult: {required, sameAs: sameAs(() => true)},
    terms: {required, sameAs: sameAs(() => true)},
  },
})
export default class AdultWarningNew extends Vue {
  // adult = '';
  terms = '';

  agree() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      localStorage.setItem('agree', 'true');
      if (vxm.user.inputLink) {
        const link = vxm.user.inputLink;
        vxm.user.inputLink = '';
        this.$router.push(link);
      } else {
        this.$router.push('/fan');
      }
    }
  }
}
